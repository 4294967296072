<script setup lang="ts">
import { ref, computed, watch, nextTick, onMounted, onUnmounted } from 'vue'
import { useRouter } from 'vue-router/composables'
import { VerifyOtpErrorResponse } from '/~/extensions/otp/composables/core/Otp'
import OtpFactory from '/~/extensions/otp/composables/core/OtpFactory'
import { pluralize } from '/~/utils/format/string'
import BaseAction from '/~/components/base/action/base-action.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useBaseInputCode } from '/~/components/base/input-code/base-input-code'
import BaseInputCode from '/~/components/base/input-code/base-input-code.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import { useAuth } from '/~/composables/auth'
import { useLogger } from '/~/composables/logger'
import { useUser } from '/~/composables/user'

const props = withDefaults(
  defineProps<{
    type?: 'account' | 'mobile' | 'email'
  }>(),
  {
    type: 'account',
  }
)

const { anotherWayOtp } = useAuth()
const logger = useLogger('verification')
const router = useRouter()
const { fetchUserDetails } = useUser()
const { code, count, inputRef, canVerify } = useBaseInputCode()

const isEnrolmentType = computed(() => ['mobile', 'email'].includes(props.type))
const triggerPoint = computed(() => {
  return isEnrolmentType.value ? 'enrolment' : 'login'
})
const otp = OtpFactory.restore(triggerPoint.value)

const timer = ref<number>(0)
const processingTimer = ref<number>(0)
const disabled = ref<boolean>(false)
const isSendingCode = ref<boolean>(false)
const isVerifyingCode = ref<boolean>(false)
const attemptsRemaining = ref(3)
const errorMessage = ref<string>('')
const errorState = ref<boolean>(false)

const processingTimeInSeconds = computed(
  () => Number(otp.processingTimeInSeconds) ?? 5
)
const validityInSeconds = computed(() => Number(otp.validityInSeconds) ?? 60)

watch(
  code,
  () => {
    errorState.value = false
  },
  { deep: true }
)

const timerInterval = ref<number>()
const timerTimeout = ref<number>()

function clearTimer() {
  logger.debug('Clearing timer')
  clearInterval(timerInterval.value)
  clearTimeout(timerTimeout.value)
  otp.timestamp = null // Also clear the saved timestamp
}

function startTimerTick() {
  clearInterval(timerInterval.value as number)
  logger.debug('Starting timer tick')
  timerInterval.value = window.setInterval(() => {
    timer.value--
    if (timer.value <= 0) {
      clearTimer()
    }
  }, 1000)
}

function startTimer() {
  const savedTimestamp = Number(otp.timestamp)
  const timePassed = (Date.now() - savedTimestamp) / 1000
  const remainingTime = Math.floor(validityInSeconds.value - timePassed)

  logger.debug('Starting timer', {
    savedTimestamp,
    timePassed,
    remainingTime,
  })

  timer.value = remainingTime > 0 ? remainingTime : 0 // Ensure timer doesn't go negative
  processingTimer.value = processingTimeInSeconds.value
  disabled.value = false
  errorState.value = false
  errorMessage.value = ''
  code.value = []
  const itemsCount = count.value

  count.value = 0
  nextTick(() => {
    count.value = itemsCount
  })
  timerTimeout.value = window.setTimeout(() => {
    inputRef.value[0]?.$el.focus()
  }, 100)

  startTimerTick()
}

onMounted(() => {
  startTimer()
})

onUnmounted(() => {
  clearTimer()
})

async function onVerify() {
  const guess = code.value.join('')

  isVerifyingCode.value = true

  try {
    if (!otp.otpId) {
      throw new Error('Otp id is missing')
    }

    await otp.verify({ guess })

    if (isEnrolmentType.value) {
      await fetchUserDetails()
      isVerifyingCode.value = true
      router.back()
    } else {
      window.location.assign('/')
    }
  } catch (e: any) {
    logger.debug('Error while verifying otp', e)
    if ((e as VerifyOtpErrorResponse).data?.attemptsRemaining) {
      attemptsRemaining.value = e.data.attemptsRemaining
    }

    timer.value = 0
    errorMessage.value = e.data?.message
    errorState.value = true
    if (errorMessage.value === 'Code has expired, please request a new code.') {
      disabled.value = true
    }

    isVerifyingCode.value = false
  }

  clearTimer()
}

async function sendAnotherCode() {
  isSendingCode.value = true
  errorState.value = false
  errorMessage.value = ''
  try {
    await otp.initiate(otp.channel)
  } catch (e: unknown) {
    logger.debug('Error while sending another code', e)
  }
  isSendingCode.value = false
  startTimer()
}

function onEnter() {
  if (canVerify.value) {
    onVerify()
  }
}
function getAnalyticsEvent(e: any[]) {
  const numberCount = e.filter((elem) => typeof elem === 'number').length

  return (
    numberCount === 6 && {
      pageGroup: 'Login',
      page: '2FA verify account',
      label: `${numberCount} digits entered`,
    }
  )
}
</script>

<template>
  <div
    class="flex w-full max-w-[640px] flex-grow flex-col justify-center bg-white p-4"
  >
    <div class="mx-auto rounded-full bg-primary-lightest p-5 text-primary">
      <base-icon :size="40" svg="heroicons/solid/shield-check" />
    </div>
    <h2 class="my-4 text-center text-2xl text-eonx-neutral-800">
      Verify your {{ type }}
    </h2>
    <p class="text-center text-eonx-neutral-600">
      We've just sent your {{ otp?.channel }} {{ otp?.name }} a one-time
      verification code
    </p>

    <base-input-code
      v-analytics:change.custom="getAnalyticsEvent"
      class="mt-12 flex justify-center"
      :error="errorState"
      :disabled="disabled"
      @enter="onEnter"
    />

    <div class="mt-8 text-center text-sm">
      <span class="mr-2 border-r pr-2 text-eonx-neutral-600">
        <span v-if="!!errorMessage" class="text-red-700">
          {{ errorMessage }}
        </span>
        <template v-else-if="timer > 0">
          Wait {{ timer }} {{ pluralize(timer, 'second') }} before requesting
          new code
        </template>
        <span v-else-if="!isSendingCode" class="text-red-700">
          Code has expired, please request a new code.
        </span>
        <span v-if="isSendingCode" class="inline-flex items-center">
          Sending another one-time code...
          <base-loader size="xs" class="ml-2" />
        </span>
      </span>
      <base-action
        v-analytics:click="{
          pageGroup: 'Login',
          page: '2FA verify account',
          cta: 'Send another code',
        }"
        class="items-center font-bold text-primary"
        :class="{
          'pointer-events-none !text-eonx-neutral-400':
            timer > 0 || isSendingCode,
        }"
        type="action"
        @click="sendAnotherCode"
      >
        Send another code
      </base-action>
      <div class="mt-4">
        <base-action
          v-if="
            !!errorMessage &&
            !isEnrolmentType &&
            otp.deliveryChannels.length > 1
          "
          class="font-bold text-primary"
          type="action"
          @click="anotherWayOtp"
        >
          Verify another way
        </base-action>
      </div>
    </div>
    <hr class="-mx-4 mb-4 mt-auto" />
    <base-button
      v-analytics:click="{
        pageGroup: 'Login',
        page: '2FA verify account',
        cta: `Verify ${type}`,
      }"
      :disabled="!canVerify || disabled"
      :loading="isVerifyingCode"
      class="disabled:!bg-eonx-neutral-200"
      @click="onVerify"
    >
      Verify {{ type }}
    </base-button>
  </div>
</template>
